<h1 id="top">Einsender</h1>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3 order-2 order-sm-1">
    <h4 id="anforderung" class="d-flex"><div class="pl-2 pt-1 pb-2 mr-auto">Anforderung von Untersuchungsanträgen und Versandmaterial</div></h4>
    <div class="px-2 mb-4">
      Sie können bequem unsere Online-Anforderung benutzen.<br/>
      Alternativ besteht die Möglichkeit, das <a href="../../assets/documents/Faxanforderung_2023.pdf" target="_blank"><img src="../../assets/images/pdf-icon.png" class="espath-icon pr-1">Formular als PDF-Datei herunterzuladen</a> und uns ausgefüllt zuzufaxen.<br/>
      <small>(Fax-Nr.: <strong>{{FAX}}</strong>)</small>


      <form *ngIf="!success && !error" [formGroup]="materialForm" (ngSubmit)="onSubmit()" class="px-3 pt-4 pb-2">

        <input id="subject" name="subject" type="hidden" formControlName="subject"/>
        <input id="url" name="url" type="text" formControlName="url"/>

        <div class="form-group">
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto">
              <label for="praxis" class="float-md-right text-md-right">Praxis*</label>
            </div>
            <div class="col-md-7 col-lg-8">
              <input id="praxis" type="text" formControlName="praxis" class="form-control"
                     [ngClass]="{'is-invalid': submitted && materialForm.controls.praxis.errors}"/>
              <div *ngIf="submitted && materialForm.controls.praxis.errors?.required" class="invalid-feedback">Bitte geben Sie Ihre Praxis an</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto">
              <label for="name" class="float-md-right text-md-right">Name*</label>
            </div>
            <div class="col-md-7 col-lg-8">
              <input id="name" type="text" formControlName="name" class="form-control"
                     [ngClass]="{'is-invalid': submitted && materialForm.controls.name.errors}"/>
              <div *ngIf="submitted && materialForm.controls.name.errors?.required" class="invalid-feedback">Bitte geben Sie Ihren Namen an</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto">
              <label for="street" class="float-md-right text-md-right">Straße / Hausnummer*</label>
            </div>
            <div class="col-md-7 col-lg-8">
              <input id="street" type="text" formControlName="street" class="form-control"
                     [ngClass]="{'is-invalid': submitted && materialForm.controls.street.errors}"/>
              <div *ngIf="submitted && materialForm.controls.street.errors?.required" class="invalid-feedback">Bitte geben Sie die Straße und die Hausnummer an</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto">
              <label for="city" class="float-md-right text-md-right">PLZ / Ort*</label>
            </div>
            <div class="col-md-7 col-lg-8">
              <input id="city" type="text" formControlName="city" class="form-control"
                     [ngClass]="{'is-invalid': submitted && materialForm.controls.city.errors}"/>
              <div *ngIf="submitted && materialForm.controls.city.errors?.required" class="invalid-feedback">Bitte geben Sie die Postleitzahl und den Ort an</div>
              <div *ngIf="submitted && !materialForm.controls.city.errors?.required && materialForm.controls.city.errors?.pattern" class="invalid-feedback">Bitte geben Sie eine gültige Postleitzahl und einen gültigen Ort ein</div>
            </div>
          </div>
        </div>

        <!-- Material -->
        <div class="mt-5 mb-3">
          <h2>Untersuchungsanträge / Versandmaterial</h2>
        </div>
        <div>
          <div *ngFor="let material of materialList; let i=index" formArrayName="material" class="form-group mb-3">
            <div class="row" formGroupName="{{i}}">
              <div class="col-md-5 col-lg-4 my-auto d-flex">
                <input id="material-count" type="number" formControlName="order_count" mask="9999" class="form-control d-inline-flex mr-3 ml-md-3"/>
                <label for="material-count" class="d-inline-flex my-auto">Stck.</label>
              </div>
              <div class="col-md-7 col-lg-8 d-block my-auto">
                <label for="material-count" class="mb-0">{{material.name}}</label>
                <div *ngIf="material.variant" class="d-block">
                  <div *ngFor="let variantText of material.variant" class="d-inline-block small justify-content-start align-items-center mr-3">
                    <input type="radio" formControlName="order_variant" value="{{variantText}}" class="mr-1"/>{{variantText}}
                  </div>
                  <div *ngIf="submitted && materialForm.controls.material.controls[i].errors?.variant_invalid" class="d-block invalid-feedback">Bitte geben Sie die gewünschte Variante an</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto"></div>
            <div class="col-md-7 col-lg-8">
              <div *ngIf="submitted && materialForm.controls.material.errors?.invalid" class="d-block invalid-feedback mb-3">Bitte wählen Sie die benötigten Mengen aus</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-12 ml-3 mr-3">
              <textarea id="comment" type="text" formControlName="comment" class="form-control" placeholder="Bemerkung"></textarea>
            </div>
          </div>
        </div>

        <div class="form-group submit">
          <div class="row">
            <div class="col-md-5 col-lg-4 my-auto">
            </div>
            <div class="col-md-7 col-lg-6">
             <button [disabled]="loading" class="btn btn-primary py-1">Bestellen</button>
            </div>
          </div>
        </div>

      </form>

      <div *ngIf="success" class="alert alert-success mt-3 mb-3">
        Vielen Dank.<br/>
        Wir haben Ihre Anforderung erhalten und werden uns schnellstmöglich darum kümmern.
      </div>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-3">
        Bei der Übertragung der Anforderung ist leider ein technisches Problem aufgetreten.<br/>
        Bitte nehmen Sie telefonisch oder per E-Mail Kontakt mit uns auf.<br/>
        Vielen Dank.
      </div>
    </div>

    <h4 id="versand" class="d-flex"><div class="pl-2 pt-1 pb-2 mr-auto">Versand der Proben und Übermittlung der Untersuchungsergebnisse</div></h4>
    <div class="px-2">
      Der Probenversand kann per Post oder über unseren Botendienst erfolgen.<br/>
      Für die Übermittlung der Untersuchungsergebnisse/Befunde stehen folgende Möglichkeiten zur Verfügung:<br/>
      <ul>
        <li>Postversand/Botendienst</li>
        <li>Fax</li>
        <li>elektronischer Befundversand (z.B. LDT, Email)</li>
      </ul>
      Zur Absprache des gewünschten Vorgehens setzen Sie sich bitte mit unserem Sekretariat in Verbindung<br/>(Tel.: <strong>{{TEL}}</strong>).
    </div>
  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3 mb-3 order-1 order-sm-2">
    <div class="bg-secondary px-2 py-2">
      <h3>Proben- / Ergebnis-Versand</h3>
      Zur Absprache des gewünschten Vorgehens:<br/>
      <ul class="pl-4">
        <li>Postversand/<wbr/>Botendienst</li>
        <li>Fax</li>
        <li>elektronischer Befundversand (z.B. LDT, Email)</li>
      </ul>
      setzen Sie sich bitte mit unserem Sekretariat in Verbindung (Tel.: <strong>{{TEL}}</strong>).
    </div>
  </div>

</div>

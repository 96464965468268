import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppConfig} from '../app.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NgbAccordion, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss']
})
export class AddonComponent implements OnInit, OnDestroy
{
    @ViewChild('acc') accordion: NgbAccordion;
    public activeId = 'kontakt';
    private subscription: Subscription;

    public PATH_1 = AppConfig.PATHOLOGE_1;
    public PATH_2 = AppConfig.PATHOLOGE_2;
    public PATH_3 = AppConfig.PATHOLOGE_3;
    public PATH_4 = AppConfig.PATHOLOGE_4;
    public TITEL = AppConfig.TITEL;
    public STRASSE = AppConfig.ADRESSE;
    public ORT = AppConfig.ORT;
    public TEL = AppConfig.TEL_ZENTRALE;
    public FAX = AppConfig.FAX_NUMMER;
    public EMAIL = AppConfig.EMAIL;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute
  )
  {
  }

  ngOnInit()
  {
      this.subscription = this.activatedRoute.params.subscribe(params =>
      {
          if (params['id'])
          {
              this.activeId = (params['id']);
          }
      });
  }

  /**
   * Panel Wechsel abfangen und per Router navigieren, damit sich auch die URL-Zeile anpasst
   * @param $event
   */
  toggleDirect($event: NgbPanelChangeEvent)
  {
      this.router.navigate(['/rechtliches', $event.panelId]);
  }

  /**
   * Panel Wechsel per zusätzlicher Links (z.B. im Block rechts)
   * @param panel
   */
  toggle(panel: string)
  {
      this.router.navigate(['/rechtliches', panel]);
  }

  ngOnDestroy()
  {
      this.subscription.unsubscribe();
  }
}

<h1 id="top">Unser Leistungsspektrum</h1>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3 order-2 order-sm-1">
    <h4 id="allgemein" class="d-flex"><div class="pl-2 pt-1 mr-auto">Allgemein</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Bearbeitung von Biopsaten und Zytologien in der Regel innerhalb eines Arbeitstages, von Resektaten/Großpräparaten innerhalb von 2 Arbeitstagen</li>
      <li>leitliniengemäße Aufarbeitung von Biopsaten und Resektaten</li>
      <li>Tumorcharakterisierung entsprechend den aktuellen Klassifikationen der WHO und UICC</li>
      <li>Immunhistochemie mit einem Pannel von >60 Antikörpern</li>
      <li>intraoperative Schnellschnittdiagnostik</li>
      <li>Möglichkeit der elektronischen Befundübermittlung</li>
    </ul>

    <h4 id="gastroenteropathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Gastroenteropathologie</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Gastritis-Klassifikation und –Graduierung (zur optimalen Beurteilung bitte nach Möglichkeit je 2 Biopsate aus Antrum und Korpus separat einsenden)</li>
      <li>Barrett-Diagnostik</li>
      <li>Diagnostik bei V.a. Sprue/Zoeliakie (Marsh-Klassifikation)</li>
      <li>Diagnostik chronisch-entzündlicher Darmerkrankungen und mikroskopischer Kolitis</li>
      <li>Diagnostik von intraepithelialen Neoplasien/Dysplasien und Polypen des oberen und unteren GI-Trakts</li>
      <li>Diagnostik bei V.a. M. Hirschsprung (bitte tel. Anmeldung 24h vor Einsendung der Biopsien frisch in feuchtem Tupfer)</li>
      <li>Histologischer Nachweis von Pilzen, Giardia lamblia, intest. Spirochätose, Kryptosporidien, Amöben, Mykobakterien u.a.</li>
      <li>CMV-Diagnostik</li>
      <li>Biopsiediagnostik entzündlicher und neoplastischer Lebererkrankungen</li>
      <li>Biopsiediagnostik neoplastischer Erkrankungen des Pankreas</li>
      <li>Leitliniengerechte Beurteilung onkologischer Resektate von Ösophagus, Magen, Darm, Leber, ableitenden Gallenwegen und Pankreas</li>
      <li>K-RAS-Mutationsanalyse bei kolorektalem Karzinom</li>
      <li>Mutationsanalyse von c-Kit und PDGFR bei GIST</li>
      <li>HNPCC-Diagnostik</li>
      <li>Kooperationspartner des Darmzentrums Esslingen und des Darmzentrums Ruit</li>
    </ul>

    <h4 id="mammapathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Mamma- und Gynäkopathologie</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>leitliniengemäße Aufarbeitung und Beurteilung von Biopsaten und Resektaten der Mamma (einschließl. B-Klassifikation), der Vulva, des inneren Genitale</li>
      <li>Immunhistochemische Bestimmung des Hormonrezeptorstatus und der Her2-Expression von Mammakarzinomen</li>
      <li>Her2-ISH wird in Kürze zur Verfügung stehen (derzeit Kooperation mit dem Pathologischen Institut der Universität Ulm).</li>
      <li>Frischgewebeasservierung für uPA- und PAI-1-Bestimmung sowie Genchip-Analyse</li>
      <li>Kooperationspartner des Brustzentrums und Zentrums für gynäkologische Tumorerkrankungen Esslingen sowie des Brustzentrums Nürtingen Ruit</li>
    </ul>

    <h4 id="uropathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Uropathologie</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>leitliniengemäße Aufarbeitung und Beurteilung von Biopsaten/TUR-Material und Resektaten von Prostata, Hoden, Niere und ableitenden Harnwegen</li>
      <li>Kooperationspartner des Prostatakarzinomzentrums Ruit</li>
      <li>Fertilitätsdiagnostik an Hodenbiopsien</li>
      <li>histologische Verifikation von Vasektomien</li>
      <li>Urinzytologie</li>
    </ul>

    <h4 id="thoraxpathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Pathologie der Thoraxorgane</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>leitliniengemäße Aufarbeitung und Beurteilung von Biopsien und onkologischen Resektaten von Lunge, Pleura und Thymus</li>
      <li>EGFR- u. K-RAS-Mutationsstatus</li>
      <li>Diagnostik interstitieller Lungenerkrankungen (an offenen Lungenbiopsaten oder VATS; in der Regel nicht/nur eingeschränkt an transbronchialen Biopsaten möglich!)</li>
      <li>Diagnostik von PCP, Alveolarproteinose etc.</li>
      <li>Kooperationspartner des Thoraxzentrums Esslingen Stuttgart (TESS)</li>
    </ul>

    <h4 id="haematopathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Hämatopathologie</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Lymphomdiagnostik</li>
      <li>Knochenmark/Beckenkammtrepanate (bitte in neutral gepuffertem Formalin und mit klin. Informationen (Blutbild!) einsenden; ggf. tel. Rücksprache)</li>
    </ul>

    <h4 id="oralpathologie" class="d-flex"><div class="pl-2 pt-1 mr-auto">Oralpathologie</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Diagnostik von odontogenen und nicht-odontogenen Zysten sowie odontogenen Tumoren</li>
      <li>Diagnostik von entzündlichen und neoplastischen Veränderungen der Mundschleimhaut (einschl. Dysplasiediagnostik) und Speicheldrüsen</li>
      <li>Speicheldrüsenbiopsien bei V.a. M. Sjögren</li>
      <li>Exfoliativzytologie</li>
    </ul>

    <h4 id="zytologische_diagnostik" class="d-flex"><div class="pl-2 pt-1 mr-auto">Zytologische Diagnostik</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Gynäkologische Vorsorgeabstriche</li>
      <li>Diagnostik von Ergüssen, Organpunktaten (z.B. Schilddrüse), Abstrichen, BAL-Flüssigkeit, Urin, Liquor</li>
    </ul>

    <h4 id="obduktionen" class="d-flex"><div class="pl-2 pt-1 mr-auto">Obduktionen/Leichenwesen</div><div pageScroll href="#top" class="oi oi-caret-top text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
    <ul class="pb-3">
      <li>Durchführung klinischer und gutachterlicher Obduktionen (nur bei Ausschluss eines nicht natürlichen Todes!)</li>
      <li>Einbalsamierung von Leichen (z.B. für den Lufttransport)</li>
    </ul>

  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3 mb-3 order-1 order-sm-2">
    <div class="bg-secondary px-2 py-2">
      <h3>Leistungs<wbr/>spektrum</h3>
      <a pageScroll href="#allgemein">Allgemein</a><br/>
      <a pageScroll href="#gastroenteropathologie">Gastroentero<wbr/>pathologie</a><br/>
      <a pageScroll href="#mammapathologie">Mamma- und Gynäkopathologie</a><br/>
      <a pageScroll href="#uropathologie">Uropathologie</a><br/>
      <a pageScroll href="#thoraxpathologie">Pathologie der Thoraxorgane</a><br/>
      <a pageScroll href="#haematopathologie">Hämatopathologie</a><br/>
      <a pageScroll href="#oralpathologie">Oralpathologie</a><br/>
      <a pageScroll href="#zytologische_diagnostik">Zytologische Diagnostik</a><br/>
      <a pageScroll href="#obduktionen">Obduktionen/<wbr/>Leichenwesen</a>
    </div>
  </div>

</div>
import {Component} from '@angular/core';

/**
 * Komponente für einen allgemeinen Header auf den einzelnen Seiten.
 *
 * @author Dominik Funk (exsportance)
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
{
  public isCollapsed = true;

  constructor()
  {
  }
}

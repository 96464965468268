import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppConfig} from '../app.constants';
import {NgbAccordion, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy
{
    @ViewChild('acc') accordion: NgbAccordion;
    public activeId = 't0';
    public PATH_1 = AppConfig.PATHOLOGE_1;
    public PATH_2 = AppConfig.PATHOLOGE_2;
    public PATH_3 = AppConfig.PATHOLOGE_3;
    public PATH_4 = AppConfig.PATHOLOGE_4;
    public TEL = AppConfig.TEL_ZENTRALE;
    public FAX = AppConfig.FAX_NUMMER;
    public EMAIL = AppConfig.EMAIL;
    private subscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    )
    {
    }

    ngOnInit()
    {
        this.subscription = this.activatedRoute.params.subscribe(params =>
        {
            if (params['id'])
            {
               this.activeId = (params['id']);
            }
        });
    }

    /**
     * Panel Wechsel abfangen und per Router navigieren, damit sich auch die URL-Zeile anpasst
     * @param $event
     */
    toggleDirect($event: NgbPanelChangeEvent)
    {
        this.router.navigate(['/team', $event.panelId]);
    }

    /**
     * Panel Wechsel per zusätzlicher Links (z.B. im Block rechts)
     * @param panel
     */
    toggle(panel: string)
    {
        this.router.navigate(['/team', panel]);
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }
}

<div class="row">
  <div class="col-12">
    <h1>Herzlich willkommen beim Institut für Pathologie Esslingen</h1>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3">
    <img src="../../assets/images/mikroskop.jpg" class="w-100">
    <p class="mt-3">
    Pathologie wird oft mit der Untersuchung von Verstorbenen in Verbindung gebracht.<br/>
    Schwerpunkt der Arbeit eines Pathologischen Instituts ist jedoch die Untersuchung von
    Gewebsproben (Biopsien), Organteilen oder Organen lebender Patienten.<br/><br/>
    Hauptwerkzeug des Pathologen ist das Mikroskop. Dabei spielt die Tumordiagnostik die größte
    Rolle. Fast jede Tumordiagnose wird letztlich vom Pathologen gestellt. Er muss - teilweise anhand
    weniger Zellen - entscheiden, ob ein Tumor gut- oder bösartig ist, welche genaue Tumorart
    vorliegt und wie weit der Tumor fortgeschritten ist. Von diesen Aussagen hängt die weitere
    Therapie des Patienten ab.<br/><br/>
    Meist ohne unmittelbaren Kontakt mit dem Patienten zu haben, übernimmt der Pathologe heute für
    die behandelnden Ärzte die Rolle eines Lotsen in der Tumortherapie.
    </p>
  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3">
    <img src="../../assets/images/gebaeude.jpg" class="w-100">
    <div class="bg-secondary small px-2 py-2">
      <div class="pt-2 pb-1">Gemeinschafts<wbr>praxis</div>
      <div>{{PATH_1}}</div>
      <div>{{PATH_2}}</div>
      <div>{{PATH_3}}</div>
      <div>{{PATH_4}}</div>
      <div class="pt-3"><strong>Pathologen und Molekularpathologie</strong></div>
      <div class="pt-2">Zertifiziert nach<br/>DIN ISO 9001:2015</div>
      <div class="pt-3"><strong>{{TITEL}}</strong></div>
      <div>{{STRASSE}}</div>
      <div>{{ORT}}</div>
      <div class="pt-3">Tel.: {{TEL}}</div>
      <div>Fax: {{FAX}}</div>
      <div>E-Mail: {{EMAIL}}</div>
    </div>
  </div>

</div>
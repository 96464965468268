import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MailService
{
    constructor(
        private http: HttpClient)
    {
    }

    sendMaterialRequestForm(value: any): Observable<any>
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8'
            })
        };

       return this.http.post('espath_mail.php', JSON.stringify(value), httpOptions);
    }
}

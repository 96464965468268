<header>
  <nav class="navbar navbar-dark navbar-expand-lg">

    <div class="justify-content-center">
      <a [routerLink]="['/']" class="navbar-brand">
        <img src="../../../assets/images/logo.png" class="logo d-inline-block pl-3 pr-3"/>
        <div class="d-none d-sm-inline-block">Institut für Pathologie</div>
      </a>
    </div>

    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav float-right text-right">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/startseite']" (click)="isCollapsed=true">Startseite</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/ueber-uns']" (click)="isCollapsed=true">Über uns</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/team']" (click)="isCollapsed=true">Team</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/leistungen']" (click)="isCollapsed=true">Leistungen</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/einsender']" (click)="isCollapsed=true">Einsender</a>
        </li>
      </ul>
    </div>

  </nav>
</header>

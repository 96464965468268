import {AbstractControl} from '@angular/forms';

/**
 * Prüft, ob bei der (Einsender-) Bestellung mindestens eine Position enthalten ist.
 * @param control Abstract Control Form
 */
export function validateOrder(control: AbstractControl)
{
    let order_position_present = false;

    if(control.value != null)
    {
        control.value.forEach(value =>
        {
            if(!(value.order_count === 0 || value.order_count == '' || value.order_count == undefined))
            {
                order_position_present = true;
            }
        })
    }

    if(order_position_present)
    {
        return null;
    }
    else
    {
        return {
            invalid: true
        };
    }
}

import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MailService} from '../_services/mail.service';
import {AppConfig} from '../app.constants';
import {validateOrder} from '../_validator/order.validator';
import {validateVariant} from '../_validator/variant.validator';

@Component({
    selector: 'einsender',
    templateUrl: './einsender.component.html',
    styleUrls: ['./einsender.component.scss']
})
export class EinsenderComponent implements OnInit
{
    materialForm: UntypedFormGroup;
    submitted = false;
    loading = false;
    success = false;
    error = false;
    materialList = [
      {name: '6ml Biopsiegefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '20ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '50ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '100ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '250ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '500ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: '1000ml Gefäß', hasVariant: true, variant: ['Mit Formalin gefüllt', 'Ungefüllt']},
      {name: 'Gefäße für Urinzytologie (Mit Alkohol gefüllt)', hasVariant: false},
      {name: 'Gefäße für KM-Stanze', hasVariant: false},
      {name: 'Eimer', hasVariant: false},
      {name: '10 Liter Formalin Kanister', hasVariant: false},
      {name: 'Versandtüten', hasVariant: false},
      {name: 'Untersuchungsanträge Histologie', hasVariant: false},
      {name: 'Untersuchungsanträge Zytologie', hasVariant: false},
      {name: 'Untersuchungsanträge Mamma', hasVariant: false},
      {name: 'Versandschachteln für Objektträger', hasVariant: false},
      {name: 'Objektträger', hasVariant: false},
      {name: 'Zytobrush', hasVariant: false}
  ];
    public TEL = AppConfig.TEL_ZENTRALE;
    public FAX = AppConfig.FAX_NUMMER;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private mailService: MailService)
    {
    }

    ngOnInit()
    {
        this.materialForm = this.formBuilder.group({
                subject: ['Website-Nachricht', ''],             // Spam-Schutz
                url: ['', ''],                                  // Spam-Schutz
                praxis: ['', [Validators.required]],
                name: ['', [Validators.required]],
                street: ['', [Validators.required]],
                city: ['', [Validators.required, Validators.pattern('^[0-9]{5}\\s.{2,}$')]],
                material: this.formBuilder.array([], [validateOrder]),
                comment: ['', ''],
            }
        );

        this.materialList.forEach(material =>
        {
            (<UntypedFormArray>this.materialForm.controls['material']).push(
                this.formBuilder.group({
                        name: [material.name, ''],
                        order_variant: ['', ''],
                        order_count: ['', ''],
                        hasVariant: [material.hasVariant, ''],
                    }, {validator: [validateVariant]}
                )
            );
        });
    }


    onSubmit()
    {
        this.submitted = true;

        // Wenn das Formular noch Fehler enthält hier abbrechen
        if (this.materialForm.invalid)
        {
            return;
        }

        this.loading = true;
        this.mailService.sendMaterialRequestForm(this.materialForm.value).subscribe(data =>
        {
            this.loading = false;
            this.success = true;
        },
        error =>
        {
            this.loading = false;
            this.error = true;
        });
    }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPageScrollModule} from 'ngx-page-scroll';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {UeberUnsComponent} from './ueber-uns/ueber-uns.component';
import {TeamComponent} from './team/team.component';
import {LeistungenComponent} from './leistungen/leistungen.component';
import {EinsenderComponent} from './einsender/einsender.component';
import {AddonComponent} from './addon/addon.component';
import {routing} from './app.routing';
import {UiModule} from './ui/ui.module';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        routing,
        UiModule,
        NgbAccordionModule,
        NgxPageScrollCoreModule.forRoot({scrollOffset: 75, duration: 200}),
        NgxPageScrollModule,
        HttpClientModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        TeamComponent,
        LeistungenComponent,
        EinsenderComponent,
        AddonComponent,
        UeberUnsComponent
    ],
    providers: [provideNgxMask()],
    bootstrap: [AppComponent]
})
export class AppModule
{
}

import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {UeberUnsComponent} from './ueber-uns/ueber-uns.component';
import {TeamComponent} from './team/team.component';
import {LeistungenComponent} from './leistungen/leistungen.component';
import {EinsenderComponent} from './einsender/einsender.component';
import {AddonComponent} from './addon/addon.component';


const appRoutes: Routes = [
  {path: 'startseite', component: HomeComponent},
  {path: 'ueber-uns', component: UeberUnsComponent},
  {path: 'team/:id', component: TeamComponent},
  {path: 'team', component: TeamComponent},
  {path: 'leistungen', component: LeistungenComponent},
  {path: 'einsender', component: EinsenderComponent},
  {path: 'rechtliches/:id', component: AddonComponent},

  // Default-Route zu "Home"
  {path: '**', redirectTo: 'startseite'}
];

export const routing = RouterModule.forRoot(appRoutes, {});

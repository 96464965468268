<h1 id="top">Rechtliches</h1>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3">
    <ngb-accordion #acc="ngbAccordion" type="secondary" closeOthers="true" activeIds="{{activeId}}" (panelChange)="toggleDirect($event)">
      <ngb-panel id="kontakt">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">Kontakt</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="pt-2 pb-1">Gemeinschafts<wbr>praxis</div>
          <div>{{PATH_1}}</div>
          <div>{{PATH_2}}</div>
          <div>{{PATH_3}}</div>
          <div>{{PATH_4}}</div>
          <div class="pt-3"><strong>{{TITEL}}</strong></div>
          <div>{{STRASSE}}</div>
          <div>{{ORT}}</div>
          <div class="pt-3">Tel.: {{TEL}}</div>
          <div>Fax: {{FAX}}</div>
          <div class="mb-2">E-Mail: {{EMAIL}}</div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="impressum">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">Impressum</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="pt-2 pb-1">Gemeinschafts<wbr>praxis</div>
          <div>{{PATH_1}}</div>
          <div>{{PATH_2}}</div>
          <div>{{PATH_3}}</div>
          <div>{{PATH_4}}</div>
          <div class="pt-3"><strong>{{TITEL}}</strong></div>
          <div>{{STRASSE}}</div>
          <div>{{ORT}}</div>
          <div class="pt-3">Tel.: {{TEL}}</div>
          <div>Fax: {{FAX}}</div>
          <div class="mb-2">E-Mail: {{EMAIL}}</div>

          <h4 class="d-flex mt-4 mb-3"><div class="pl-2 py-1 mr-auto">Zuständige Aufsichtsbehörde</div></h4>
          <div class="px-2">
            <strong>Landesärztekammer Baden-Württemberg</strong><br/>
            Bezirksärztekammer Nordwürttemberg<br/>
            Jahnstraße 5<br/>
            70597 Stuttgart
          </div>
          <div class="px-2 pt-2">
            <strong>Kassenärztliche Vereinigung Baden-Württemberg</strong><br/>
            Bezirksdirektion Stuttgart<br/>
            Albstadtweg 11<br/>
            70567 Stuttgart
          </div>

          <h4 class="d-flex mt-4 mb-3"><div class="pl-2 py-1 mr-auto">Zuständige Kammer</div></h4>
          <div class="px-2">
            <strong>Landesärztekammer Baden-Württemberg</strong><br/>
            Bezirksärztekammer Nordwürttemberg<br/>
            Jahnstraße 5<br/>
            70597 Stuttgart
          </div>

          <h4 class="d-flex mt-4 mb-3"><div class="pl-2 py-1 mr-auto">Berufsbezeichnung</div></h4>
          <div class="px-2">
            Facharzt für Pathologie, Gynäkologische Exfoliativ-Zytolgie, Zytologie, Molekularpathologie<br/>
            <br/>
            <a href="https://www.aerztekammer-bw.de/10aerzte/40merkblaetter/20recht/05kammerrecht/bo.pdf" target="_blank">Berufsordnung der Landesärztekammer Baden-Württemberg</a><br/>
            <a href="https://www.aerztekammer-bw.de/10aerzte/40merkblaetter/20recht/05kammerrecht/index.html" target="_blank">Kammerrecht der Landesärztekammer Baden-Württemberg</a><br/>
            <a href="https://www.aerztekammer-bw.de/10aerzte/40merkblaetter/20recht/10gesetze/hbkg.pdf" target="_blank">Heilberufe-Kammergesetz Baden-Württemberg (HBKG)</a>
          </div>

          <h4 class="d-flex mt-4 mb-3"><div class="pl-2 py-1 mr-auto">Disclaimer</div></h4>
          <div class="px-2">
            <h6>Inhalt des Onlineangebots</h6>
            <div>
              Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
              Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
            </div>
            <h6 class="mt-3">Verweise und Links</h6>
            <div>
              Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
              Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
            </div>
            <h6 class="mt-3">Urheber und Kennzeichenrecht</h6>
            <div>
              Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.
              Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
              Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
            </div>
            <h6 class="mt-3">Datenschutz</h6>
            <div>
              siehe <a [routerLink]="['/rechtliches', 'datenschutz']">Datenschutzerklärung</a>
            </div>
            <h6 class="mt-3">Rechtswirksamkeit dieses Haftungsausschlusses</h6>
            <div>
              Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
            </div>
            <br/>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="datenschutz">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">Datenschutzerklärung</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit
             sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive
             seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
          <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“
             genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder
             jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation,
             das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
             Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung,
            die Einschränkung, das Löschen oder die Vernichtung.</p>
          <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und
             Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über
             die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
             Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
            Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>

          Unsere Datenschutzerklärung ist wie folgt gegliedert:<br/>
          I. Informationen über uns als Verantwortliche<br/>
          II. Rechte der Nutzer und Betroffenen<br/>
          III. Informationen zur Datenverarbeitung<br/>
          <br/>
          <h6>I. Informationen über uns als Verantwortliche</h6>
          Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:<br/>
          <br/>
          <p>
            Berufsausübungsgemeinschaft<br/>
            {{PATH_1}}<br/>
            {{PATH_2}}<br/>
            {{PATH_3}}<br/>
            {{PATH_4}}<br/>
            <br/>
            {{TITEL}}<br/>
            {{STRASSE}}<br/>
            {{ORT}}<br/>
            Deutschland<br/><br/>
            Telefon: {{TEL}}<br/>
            Telefax: {{FAX}}<br/>
            E-Mail: {{EMAIL}}<br/>
          </p>
          <p>Datenschutzbeauftragter beim Anbieter ist:</p>
          <p>
            Herr Matthias Walliser<br/>
            c/o {{TITEL}}<br/>
            {{STRASSE}}<br/>
            {{ORT}}<br/>
            Deutschland<br/><br/>
            Telefon: {{TEL}}<br/>
            Telefax: {{FAX}}<br/>
            E-Mail: {{EMAIL}}<br/>
          </p>
          <h6>II. Rechte der Nutzer und Betroffenen</h6>
          Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht
          <ul>
            <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten,
                auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
            <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
            <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit
                eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung
                nach Maßgabe von Art. 18 DSGVO;</li>
            <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten
                an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
            <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden
                Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden
                (vgl. auch Art. 77 DSGVO).</li>
          </ul>
          <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt
             worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund
             der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
             Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein
             Recht auf Auskunft über diese Empfänger.</p>
          <p>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung
             der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet
             werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</p>

          <h6>III. Informationen zur Datenverarbeitung</h6>
          <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der
             Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend
             keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>

          <h6 class="mt-3">Serverdaten</h6>
          <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten
             durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles
             werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren
             Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum
             und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres
             Internetauftritts erfolgt, erhoben.</p>
          <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
          <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse
             liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>
          <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken
             erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von
             der Löschung ausgenommen.</p>

          <h6 class="mt-3">Vertragsabwicklung</h6>
          <p>Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots übermittelten Daten werden von uns
             zum Zwecke der Vertragsabwicklung verarbeitet und sind insoweit erforderlich. Vertragsschluss und Vertragsabwicklung
             sind ohne Bereitstellung Ihrer Daten nicht möglich.</p>
          <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
          <p>Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen
             Aufbewahrungsfristen beachten.</p>
          <p>Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit der Warenlieferung beauftragte Transportunternehmen
             oder an den Finanzdienstleister weiter, soweit die Weitergabe zur Warenauslieferung oder zu Bezahlzwecken erforderlich
             ist.</p>
          <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>

          <h6 class="mt-3">Kontaktanfragen / Kontaktmöglichkeit</h6>
          <p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur
            Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich -
            ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>
          <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
          <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>
          <a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank">Muster-Datenschutzerklärung</a>
          der <a href="https://www.ratgeberrecht.eu/" target="_blank">Anwaltskanzlei Weiß & Partner</a>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>
  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3 d-none d-sm-inline-block">
    <div class="bg-secondary px-2 py-2">
      <h3>Rechtliches</h3>
      <a (click)="toggle('kontakt')" class="text-primary">Kontakt</a><br/>
      <a (click)="toggle('impressum')" class="text-primary">Impressum</a><br/>
      <a (click)="toggle('datenschutz')" class="text-primary">Datenschutzerklärung</a><br/>
    </div>
  </div>

</div>
import {Component} from '@angular/core';

/**
 * Komponente für das allgemeine Layout mit Header und Footer.
 *
 * @author Dominik Funk (exsportance)
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent
{
  constructor()
  {
  }
}

import {Component} from '@angular/core';
import {AppConfig} from '../app.constants';

@Component({
    selector: 'ueber-uns',
    templateUrl: './ueber-uns.component.html',
    styleUrls: ['./ueber-uns.component.scss']
})
export class UeberUnsComponent
{

    public PATH_1 = AppConfig.PATHOLOGE_1;
    public PATH_2 = AppConfig.PATHOLOGE_2;
    public PATH_3 = AppConfig.PATHOLOGE_3;
    public PATH_4 = AppConfig.PATHOLOGE_4;
    public TITEL = AppConfig.TITEL;
    public STRASSE = AppConfig.ADRESSE;
    public ORT = AppConfig.ORT;
    public TEL = AppConfig.TEL_ZENTRALE;
    public FAX = AppConfig.FAX_NUMMER;
    public EMAIL = AppConfig.EMAIL;

    constructor()
    {
    }
}

<h1 id="top">Wir über uns</h1>

<div class="row">

  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3">
    Das Institut für Pathologie in Esslingen besteht seit den 1960er Jahren.<br/>
    <br/>
    Als Institut der Städtischen Kliniken Esslingen wurde es zunächst von Herrn Prof. Dr. Schneider, ab 1985 von Herrn PD Dr. H. H. Dahm als Chefarzt geleitet.
    Seit 1994 besteht es als privates Institut, zunächst in den Räumen des Klinikums Esslingen, weiter. Im Herbst 2023 wurde wegen den geplanten Um- und Neubaumaßnahmen des Klinikums Esslingen ein Umzug in das Industriegebiet in Esslingen-Zell nötig.<br/>
    <br/>
    Herr <a [routerLink]="['/team', 't1']">Prof. Dr. med. Jörn Sträter</a> ist seit Anfang 2005, Herr <a [routerLink]="['/team', 't2']">Dr. med. Steffen Sonnentag</a> seit Oktober 2006 Partner des als Gemeinschaftspraxis geführten
    Instituts. Anfang April 2009 trat Frau <a [routerLink]="['/team', 't3']">Dr. med. Kerstin Henning</a> für den sich in den Ruhestand verabschiedenden Dr. Dahm in das Institut ein. Anfang März 2019 komplettierte dann Herr
    <a [routerLink]="['/team', 't4']">Dr. med. Manfred Richter</a> das aktuelle Team aus vier Pathologen.<br/>
    <br/>
    Unser Institut ist Kooperationspartner des <a href="http://www.klinikum-esslingen.de/" target="_blank">Klinikums Esslingen</a>, des <a href="https://www.medius-kliniken.de/" target="_blank">Paracelsus-Krankenhauses Ruit</a> sowie zahlreicher Arztpraxen im Großraum
    Stuttgart/Esslingen/Reutlingen. Weiterhin sind wir Teil des Interdisziplinären Mammazentrums Esslingen, der Darmzentren Esslingen und Ruit,
    des Brustzentrums Nürtingen-Ruit, des Prostatakarzinomzentrums Ruit und des Thoraxzentrums Esslingen-Stuttgart (TESS).<br/>
    <br/>
    Die Schwerpunkte unseres Institutes liegen im Bereich der allgemeinen onkologischen Pathologie, Gastroenteropathologie, der Mamma-, Uro- und Oralpathologie.<br/>
    <br/>
    Unser Institut ist seit August 2004 zertifiziert, aktuell nach DIN EN ISO 9001:2015<br/>
    <br/>
  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3">
    <img src="../../assets/images/gebaeude.jpg" class="w-100">
    <div class="bg-secondary small px-2 py-2">
      <div class="pt-2 pb-1">Gemeinschafts<wbr>praxis</div>
      <div>{{PATH_1}}</div>
      <div>{{PATH_2}}</div>
      <div>{{PATH_3}}</div>
      <div>{{PATH_4}}</div>
      <div class="pt-3"><strong>Pathologen und Molekularpathologie</strong></div>
      <div class="pt-2">Zertifiziert nach<br/>DIN ISO 9001:2015</div>
      <div class="pt-3"><strong>{{TITEL}}</strong></div>
      <div>{{STRASSE}}</div>
      <div>{{ORT}}</div>
      <div class="pt-3">Tel.: {{TEL}}</div>
      <div>Fax: {{FAX}}</div>
      <div>E-Mail: {{EMAIL}}</div>
    </div>
  </div>

</div>

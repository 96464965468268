<h1 id="top">Unser Team</h1>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-9 pr-sm-3">
    <ngb-accordion #acc="ngbAccordion" type="secondary" closeOthers="true" activeIds="{{activeId}}"  (panelChange)="toggleDirect($event)">
      <ngb-panel id="t0">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">Unser Praxisteam</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <!--img src="../../assets/images/praxisteam.jpg" class="mw-100 mb-4"-->
          <div class="row mb-2">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <h4 id="laborleitung" class="d-flex"><div class="pl-2 py-1 mr-auto">Laborleitung</div></h4>
              <div class="row mt-2 pl-2">
                <div class="col-12 col-lg-4">
                  <!--img src="../../assets/images/kudu.jpg" class="mw-100"-->
                </div>
                <div class="col-12 col-lg-8 pt-2 pt-lg-0">
                  <strong>Frau S. Inan</strong><br/>
                  Tel.: {{TEL}}<br/>
                  Fax: {{FAX}}<br/>
                  E-Mail: {{EMAIL}}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <h4 id="sekretariatsleitung" class="d-flex"><div class="pl-2 py-1 mr-auto">Sekretariatsleitung</div></h4>
              <div class="row mt-2 pl-2">
                <div class="col-12 col-lg-4">
                  <!--img src="../../assets/images/selling.jpg" class="mw-100"-->
                </div>
                <div class="col-12 col-lg-8 pt-2 pt-lg-0">
                  <strong>Frau Selling</strong><br/>
                  Tel.: {{TEL}}<br/>
                  Fax: {{FAX}}<br/>
                  E-Mail: {{EMAIL}}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="t1">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">{{PATH_1}}</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <!--img src="../../assets/images/straeter.jpg" class="mw-100 mb-4"-->
          <h4 id="vita_1" class="d-flex"><div class="pl-2 py-1 mr-auto">Vita</div></h4>
          <table class="table table-sm">
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1985-1992</td>
              <td class="col-9 d-inline-flex">Studium der Humanmedizin in Bonn und Kiel</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1994</td>
              <td class="col-9 d-inline-flex">Promotion am Pathologischen Institut der Universität Kiel</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1992-1996</td>
              <td class="col-9 d-inline-flex">Facharztausbildung am Pathologischen Institut der Universität Heidelberg und Ulm</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1996-2004</td>
              <td class="col-9 d-inline-flex">Oberarzt der Abt. Pathologie der Universität Ulm</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1998</td>
              <td class="col-9 d-inline-flex">Adolf-Kussmaul-Preis der Südwestdt. Gesellschaft für Gastroenterologie und Forschungspreis der Deutschen M. Crohn/ Colitis ulcerosa Vereinigung (DCCV)</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2003</td>
              <td class="col-9 d-inline-flex">Fakultative Weiterbildung Molekularpathologie</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2003</td>
              <td class="col-9 d-inline-flex">Lehrbefugnis für das Fach „Allgemeine Pathologie und Pathologische Anatomie“ der Universität Ulm</td>
            </tr>
          </table>

          <h4 id="mitgliedschaft_1" class="d-flex"><div class="pl-2 py-1 mr-auto">Mitgliedschaft</div></h4>
          <ul class="mt-2">
            <li>Deutsche Gesellschaft für Pathologie</li>
            <li>Internationale Akademie für Pathologie, Deutsche Abteilung</li>
            <li>Bundesverband Deutscher Pathologen</li>
            <li>Deutsche Gesellschaft für Verdauungs- und Stoffwechselkrankheiten</li>
            <li>Deutsche Gesellschaft für Senologie</li>
          </ul>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="t2">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">{{PATH_2}}</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <!--img src="../../assets/images/sonnentag.jpg" class="mw-100 mb-4"-->
          <h4 id="vita_2" class="d-flex"><div class="pl-2 py-1 mr-auto">Vita</div></h4>
          <table class="table table-sm">
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1991-1997</td>
              <td class="col-9 d-inline-flex">Studium der Humanmedizin in München und Heidelberg</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1998</td>
              <td class="col-9 d-inline-flex">Promotion an der Chirurgischen Universitätsklinik Heidelberg</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1997-1998</td>
              <td class="col-9 d-inline-flex">Klinisches Jahr an der Chirurgischen Klinik des Bürgerhospitals Stuttgart</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1999-2004</td>
              <td class="col-9 d-inline-flex">Facharztausbildung am Pathologischen Institut des Universitätsklinikum Mannheim</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2004-2006</td>
              <td class="col-9 d-inline-flex">Facharzt und Oberarzt am Pathologischen Institut des Universitätsklinikum Mannheim und am Klinikum Ludwigshafen</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2006</td>
              <td class="col-9 d-inline-flex">Niederlassung als Pathologe</td>
            </tr>
          </table>

          <h4 id="mitgliedschaft_2" class="d-flex"><div class="pl-2 py-1 mr-auto">Mitgliedschaft</div></h4>
          <ul class="mt-2">
            <li>Internationale Akademie für Pathologie</li>
            <li>Berufsverband Deutscher Pathologen</li>
          </ul>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="t3">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">{{PATH_3}}</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <!--img src="../../assets/images/henning.jpg" class="mw-100 mb-4"-->
          <h4 id="vita_3" class="d-flex"><div class="pl-2 py-1 mr-auto">Vita</div></h4>
          <table class="table table-sm">
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1984 - 1985</td>
              <td class="col-9 d-inline-flex">praktisches Jahr im Agricola Krankenhaus Saalfeld</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1985 - 1991</td>
              <td class="col-9 d-inline-flex">Studium der Humanmedizin an der Friedrich Schiller Universität Jena</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1991</td>
              <td class="col-9 d-inline-flex">Verleihung des akademischen Grades Diplom Mediziner</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1991 - 1992</td>
              <td class="col-9 d-inline-flex">Arzt im Praktikum in der Gynäkologie und Geburtshilfe</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1993 - 2003</td>
              <td class="col-9 d-inline-flex">Assistenzarzt am Institut für Pathologie der Friedrich Schiller Universität Jena</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2003</td>
              <td class="col-9 d-inline-flex">Anerkennung als Facharzt für Pathologie</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2003 - 2004</td>
              <td class="col-9 d-inline-flex">Facharzt am Institut für Pathologie der Friedrich Schiller Universität Jena</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2004 - 2008</td>
              <td class="col-9 d-inline-flex">Oberärztin am Institut für Pathologie der Städtisches Klinikum Brandenburg GmbH</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2005</td>
              <td class="col-9 d-inline-flex">Verleihung des „doctor medicinae“ (Dr. med.)</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2009</td>
              <td class="col-9 d-inline-flex">Eintritt als Gesellschafter in die Gemeinschaftspraxis Institut für Pathologie in Esslingen am Neckar</td>
            </tr>
          </table>

          <h4 id="mitgliedschaft_3" class="d-flex"><div class="pl-2 py-1 mr-auto">Mitgliedschaft</div></h4>
          <ul class="mt-2">
            <li>Internationale Akademie für Pathologie</li>
            <li>Berufsverband Deutscher Pathologen</li>
          </ul>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="t4">
        <ng-template ngbPanelTitle>
          <h4 class="d-flex"><div class="pl-2 pt-1 mr-auto">{{PATH_4}}</div><div class="oi oi-plus text-white bg-primary my-auto mr-0 px-2 py-2"></div></h4>
        </ng-template>
        <ng-template ngbPanelContent>
          <h4 id="vita_3" class="d-flex"><div class="pl-2 py-1 mr-auto">Vita</div></h4>
          <table class="table table-sm">
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">1993 - 2000</td>
              <td class="col-9 d-inline-flex">Studium der Humanmedizin an den Universitäten Tübingen, Bordeaux und Freiburg</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2000</td>
              <td class="col-9 d-inline-flex">Promotion Hämatologie/Onkologie Universität Freiburg, Prof. Dr. J. Finke</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2001 - 2009</td>
              <td class="col-9 d-inline-flex">Facharztausbildung Uniklinikum Frankfurt, TUM München, Klinikum Nürnberg und Kantonsspital Winterthur</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2009</td>
              <td class="col-9 d-inline-flex">Facharzt für Pathologie (FMH)</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2009 - 2010</td>
              <td class="col-9 d-inline-flex">Oberarzt Kantonsspital Baden</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2011 - 2012</td>
              <td class="col-9 d-inline-flex">Kantonsspital Sankt Gallen, Weiterbildung Schwerpunkt Zytopathologie</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2012 - 2015</td>
              <td class="col-9 d-inline-flex">Chefarzt Alb-Fils-Kliniken Göppingen</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">2016 - 2017</td>
              <td class="col-9 d-inline-flex">Praxis für Pathologie, Stuttgart</td>
            </tr>
            <tr class="row mx-0 d-flex">
              <td class="col-3 d-inline-flex">seit 2018</td>
              <td class="col-9 d-inline-flex">Institut für Pathologie Esslingen am Neckar</td>
            </tr>
          </table>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div class="col-12 col-sm-4 col-lg-3 pl-sm-3 d-none d-sm-inline-block">
    <div class="bg-secondary px-2 py-2">
      <h3>Team</h3>
      <a (click)="toggle('t0')" class="text-primary">Unser Praxisteam</a><br/>
      <a (click)="toggle('t1')" class="text-primary">{{PATH_1}}</a><br/>
      <a (click)="toggle('t2')" class="text-primary">{{PATH_2}}</a><br/>
      <a (click)="toggle('t3')" class="text-primary">{{PATH_3}}</a><br/>
      <a (click)="toggle('t4')" class="text-primary">{{PATH_4}}</a><br/>
    </div>
  </div>

</div>

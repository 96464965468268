/**
 * Konstanten, um wechselnde Daten (z.B. Anschrift, Telefonnumer u.ä.) zentral ändern zu können.
 */

export const AppConfig = {
    TITEL: 'Institut für Pathologie',
    PATHOLOGE_1: 'Prof. Dr. med. Jörn Sträter',
    PATHOLOGE_2: 'Dr. med. Steffen Sonnentag',
    PATHOLOGE_3: 'Dr. med. Kerstin Henning',
    PATHOLOGE_4: 'Dr. med. Manfred Richter',
    ADRESSE: 'Röntgenstr. 12/3',
    ORT: '73730 Esslingen-Zell',
    TEL_ZENTRALE: '0711/93 92 06-0',
    FAX_NUMMER: '0711/93 92 06-22',
    EMAIL: 'info@patho-es.de'
};

import {Component} from '@angular/core';

/**
 * Komponente für einen allgemeinen Footer auf den einzelnen Seiten.
 *
 * @author Dominik Funk (exsportance)
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent
{
  constructor()
  {
  }
}
